import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";

const InfoDialog = forwardRef((props, ref) => {

    const theme = useTheme();

    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        open() {
            setOpen(true);
        }
    }));

    const handleClose = () => {
        setOpen(false);
    }

    const bull = (
        <Box
            component="span"
            sx={{ display: 'inline-block', mx: '2px', transform: 'scale(1.3)', pb: .5, mr: 1 }}
        >
            •
        </Box>
    );

    return (
        <Dialog
            fullWidth
            maxWidth={'lg'}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle fontWeight={600} sx={{ textAlign: "center" }}>Welche Daten benötigen wir  von Ihnen:</DialogTitle>
            <DialogContent>
                <Typography variant="body1" color={theme.palette.text.primary} sx={{ mx: 2, lineHeight: "normal" }}>{bull}Schadendatum</Typography>
                <Typography variant="body1" color={theme.palette.text.primary} sx={{ mx: 2, mt: .5, lineHeight: "normal" }}>{bull}Uhrzeit</Typography>
                <Typography variant="body1" color={theme.palette.text.primary} sx={{ mx: 2, mt: .5, lineHeight: "normal" }}>{bull}Schadenort</Typography>
                <Typography variant="body1" color={theme.palette.text.primary} sx={{ mx: 2, mt: .5, lineHeight: "normal" }}>{bull}Schadenhergang</Typography>
                <Typography variant="body1" color={theme.palette.text.primary} sx={{ mx: 2, mt: .5, lineHeight: "normal" }}>{bull}Was ist beschädigt</Typography>
                <Typography variant="body1" color={theme.palette.text.primary} sx={{ mx: 2, mt: .5, lineHeight: "normal" }}>{bull}Kennzeichnen bei KFZ</Typography>
                <Typography variant="body1" color={theme.palette.text.primary} sx={{ mx: 2, mt: .5, lineHeight: "normal" }}>{bull}Versicherungsscheinnummer oder Name und Adresse des Versicherungsnehmer</Typography>
                <Typography variant="body1" color={theme.palette.text.primary} sx={{ mx: 2, mt: .5, lineHeight: "normal" }}>{bull}Optional können Sie Bilder zum Schaden hochladen</Typography>
                <Typography variant="body1" color={theme.palette.text.primary} sx={{ mx: 2, mt: .5, lineHeight: "normal" }}>{bull}Optional können Sie Dokumente zum Schaden (Kostenvoranschlag, Polizeibericht, Rechnung usw.) hochladen</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" disableElevation onClick={handleClose} sx={{ textTransform: "none", bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main", "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" } }}>Schließen</Button>
            </DialogActions>
        </Dialog>
    );

});

export default InfoDialog;