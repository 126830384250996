import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useMediaQuery, useTheme } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";

const NotImplementedDialog = forwardRef((props, ref) => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));

    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        open() {
            setOpen(true);
        }
    }));

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Dialog
            fullWidth
            maxWidth={belowsm ? 'lg' : "sm"}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle fontWeight={600} sx={{ textAlign: "center" }}>¯\_(ツ)_/¯ Noch nicht verfügbar</DialogTitle>
            <DialogContent>
                <Typography variant="body1" color={theme.palette.text.primary} sx={{ textAlign: "center", lineHeight: "normal" }}>Diese Funktionalität ist noch nicht verfügbar.</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" disableElevation onClick={handleClose} sx={{ textTransform: "none", bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main", "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" } }}>Schließen</Button>
            </DialogActions>
        </Dialog>
    );

});

export default NotImplementedDialog;