import { Backdrop, Box, CircularProgress, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material";
import useAxios from "../utlis/useAxios";
import { Fragment, useEffect, useRef, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ClaimDialog from "./Dialog/ClaimDialog";

const Claims = () => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));

    const navigate = useNavigate();

    const [claims, setClaims] = useState([]);
    const [rows, setRows] = useState(20);
    const [activePage, setActivePage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [failed, setFailed] = useState(false);

    const pageable = useRef({
        "size": 20,
        "currentPage": 0,
        "empty": true,
        "totalElements": 0,
        "totalPages": 0
    });
    const attempted = useRef(false);
    const claimRef = useRef(null);

    const { axiosInstance, isAxiosInitialized } = useAxios();

    useEffect(() => {
        if (!attempted.current && claims?.length === 0 && isAxiosInitialized) {
            fetchClaims();
        };
        //eslint-disable-next-line
    }, [claims, attempted.current, isAxiosInitialized]);

    const fetchClaims = async (size, page) => {
        if (!isAxiosInitialized) return;
        if (claims?.filter(c => c.page === page)?.length > 0) {
            setActivePage(page || 0);
            pageable.current({
                ...pageable.current,
                "currentPage": page ? page : 0
            });
            return;
        };
        setLoading(true);

        await axiosInstance({
            url: `/claims${size ? `?size=${size}` : ""}${page && !size ? `?page=${page}` : page && size ? `&page=${page}` : ""}`,
            method: "GET"
        }).then((res) => {
            const newClaims = [
                ...claims,
                {
                    "page": page ? page : 0,
                    "content": res.data?.content
                }];
            setClaims(newClaims);
            setActivePage(page || 0);
            pageable.current = ({
                ...pageable.current,
                "size": res.data?.size,
                "currentPage": page ? page : 0,
                "empty": res.data?.empty,
                "totalElements": res.data?.totalElements,
                "totalPages": res.data?.totalPages
            });
        }).catch(() => setFailed(true));
        attempted.current = true;
        setLoading(false);
    };

    const handleChangePage = (event, newPage) => {
        if (newPage > activePage && claims?.filter(c => c.page === newPage)?.length === 0) {
            fetchClaims(pageable.current.size, newPage);
        } else {
            setActivePage(newPage);
        };
    };

    const handleChangeRowsPerPage = (event) => {
        setRows(+event.target.value);
        pageable.current = ({
            ...pageable.current,
            "size": +event.target.value,
            "currentPage": 0
        })
        fetchClaims(+event.target.value);
    };

    const Claim = ({ data }) => {

        const handleClick = () => {
            if (belowsm) {
                claimRef.current.open(data);
            } else {
                localStorage.setItem("claim", data?.claimUUID);
                navigate(`/complete?claim=${data?.claimUUID}`)
            }
        };

        return (
            <TableRow onClick={handleClick} sx={{ cursor: "pointer" }}>
                <TableCell align="left">{moment(data?.sentAt).format("DD.MM.YYYY") ?? "Nicht vorhanden"}</TableCell>
                <TableCell align="left">{data?.sender ?? "Nicht vorhanden"}</TableCell>
                <TableCell align="left">{data?.policy ?? "Nicht vorhanden"}</TableCell>
                <TableCell align="left">{data?.insurance ?? "Nicht vorhanden"}</TableCell>
                <TableCell align="left">{data?.risk ?? "Nicht vorhanden"}</TableCell>
                <TableCell align="left">{data?.cause ?? "Nicht vorhanden"}</TableCell>
                <TableCell align="left">{data?.lossDescription ?? "Nicht vorhanden"}</TableCell>
            </TableRow>
        );
    };

    if (loading) {
        return (
            <Grid container sx={{ p: 2, height: belowmd ? "calc(100vh - 118px)" : "calc(100vh - 64px)" }}>
                <Backdrop
                    sx={{ color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Grid>
        );
    };

    return (
        <Grid container sx={{ p: 2, height: belowmd && !belowsm ? "calc(100vh - 120px)" : "calc(100vh - 76px)" }}>
            <Grid item xs={12} sx={{ border: 1, borderColor: "text.secondary", borderRadius: "4px" }}>
                <Typography variant="h6" noWrap fontWeight={600} color={theme.palette.text.primary} sx={{ py: .25, px: 1, bgcolor: "divider", borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }}>Meine Schäden</Typography>
                <Box sx={{ p: 1 }}>
                    {failed ?
                        <Box sx={{ width: "100%", height: "75vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Typography color={theme.palette.text.primary} sx={{ textAlign: "center" }}>Fehler mit der Anzeige der existierenden Schäden.</Typography>
                        </Box>
                        :
                        <Fragment>
                            <TableContainer sx={{ maxHeight: "75vh" }}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" sx={{ width: "125px" }}>Gesendet am</TableCell>
                                            <TableCell align="left">Melder</TableCell>
                                            <TableCell align="left">Polizze</TableCell>
                                            <TableCell align="left">Versicherung</TableCell>
                                            <TableCell align="left">Risiko</TableCell>
                                            <TableCell align="left">Ursache</TableCell>
                                            <TableCell align="left">Schadenbeschreibung</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {claims?.filter(c => c.page === activePage)[0]?.content?.map((c, i) => { return <Claim key={i} data={c} /> })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                // rowsPerPageOptions={[20, 100]}
                                rowsPerPageOptions={[20]}
                                component="div"
                                count={pageable.current.totalElements}
                                rowsPerPage={rows}
                                page={activePage}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Fragment>
                    }
                </Box>
                <ClaimDialog ref={claimRef} />
            </Grid>
        </Grid>
    );
};

export default Claims;