import { useAuth0 } from '@auth0/auth0-react';
import CloseIcon from '@mui/icons-material/Close';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, Collapse, Divider, Grid, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Paper, useMediaQuery, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import swiftRobin from '../images/swift_robin.png';

const Nav = () => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));

    const { isAuthenticated, user, logout } = useAuth0();

    const navigate = useNavigate();
    const location = useLocation();

    const [collapse, setCollapse] = useState(false);
    const [userCollapse, setUserCollapse] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [displayImg, setDisplayImg] = useState(false);

    const open = Boolean(anchorEl);

    useEffect(() => {
        if (!belowsm) {
            setCollapse(false);
            setUserCollapse(false);
        } else {
            setAnchorEl(null);
        }
        if (user?.picture) {
            const img = new Image();
            img.src = user?.picture;
            img.onload = () => setDisplayImg(true);
            img.onerror = () => setDisplayImg(false);
        };
        document.body.style.backgroundColor = theme.palette.background.default;
        //eslint-disable-next-line
    }, [belowsm, theme.palette.mode, user?.picture]);

    const handleClick = () => {
        setCollapse(!collapse);
        setUserCollapse(false);
    };

    const handleUserClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileUserClick = () => {
        setUserCollapse(!userCollapse);
        setCollapse(false);
    };

    const handleUserClose = () => {
        setAnchorEl(null);
    }

    const handleLogout = () => {
        handleUserClose();
        setUserCollapse(false);
        logout({ logoutParams: { returnTo: `${window.location.origin}/login` } });
    };

    const handlePageChange = (path) => {
        navigate(path);
        setCollapse(!collapse);
    };

    return (
        <AppBar position="sticky" sx={{ boxShadow: "none", display: isAuthenticated ? "flex" : "none" }}>
            <Toolbar>
                <Grid container flexDirection="column">
                    <Grid item xs={12} sx={{ display: "flex", flexDirection: "row" }}>
                        <IconButton
                            onClick={handleClick}
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: belowsm ? 0 : 2, display: belowsm ? "inline-flex" : "none" }}
                        >
                            {collapse ? <CloseIcon /> : <MenuIcon />}
                        </IconButton>
                        <Box sx={{ mt: belowsm ? 0 : .5, flexGrow: belowsm ? 1 : 0, display: "flex", justifyContent: "center", alignItems: "center", height: "64px" }}>
                            <img alt="logo" src={swiftRobin} style={{ height: "65%" }} />
                            <Typography variant="h6" fontWeight={700} component="div" noWrap color={theme.palette.mode === "dark" ? theme.palette.primary.light : "inherit"} sx={{ ml: 2, display: belowsm ? "none" : "flex" }}>
                                Swift Robin
                            </Typography>
                        </Box>
                        <Box sx={{ flexGrow: 1, display: belowsm ? "none" : "flex" }} />
                        <Box sx={{ display: belowmd ? "none" : "flex", justifyContent: "center", mr: 2 }}>
                            <Button size="large" onClick={() => { navigate("/report") }} variant="outlined" color="inherit" disableRipple sx={{ mr: 2, my: 2, textTransform: "none", textDecoration: location.pathname === "/report" ? "underline" : "none", "&:hover": { textDecoration: "underline" } }}>Schaden melden</Button>
                            <Button size="large" onClick={() => { navigate("/complete") }} variant="outlined" color="inherit" disableRipple sx={{ mr: 2, my: 2, textTransform: "none", textDecoration: location.pathname === "/complete" ? "underline" : "none", "&:hover": { textDecoration: "underline" } }}>Meldung anzeigen</Button>
                            <Button size="large" onClick={() => { navigate("/claims") }} variant="outlined" color="inherit" disableRipple sx={{ my: 2, textTransform: "none", textDecoration: location.pathname === "/claims" ? "underline" : "none", "&:hover": { textDecoration: "underline" } }}>Meine Schäden</Button>
                        </Box>
                        <IconButton onClick={belowsm ? handleMobileUserClick : handleUserClick} sx={{ p: 0, mt: belowmd && !belowsm ? 1 : 0, my: belowmd && !belowsm ? 0 : 1 }}>
                            <Avatar sx={{ bgcolor: "transparent" }}>
                                {displayImg ? <img src={user?.picture} alt="aktiver-benutzer" style={{ width: "100%", height: "100%" }} /> : <AccountCircleIcon sx={{ fontSize: 42, color: theme.palette.mode === "dark" ? theme.palette.text.primary : theme.palette.primary.contrastText }} />}
                            </Avatar>
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            onClose={handleUserClose}
                            slotProps={{
                                paper: {
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: -1,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&::before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }
                            }}
                        >
                            <MenuItem onClick={handleLogout} sx={{ py: 0.5, px: 1, borderRadius: "8px" }}>
                                <ExitToAppIcon sx={{ color: theme.palette.text.primary, mr: 1 }} />
                                <Typography color={theme.palette.text.primary}>Abmelden</Typography>
                            </MenuItem>
                        </Menu>
                    </Grid>
                    <Grid item xs={12} sx={{ display: belowmd && !belowsm ? "block" : "none" }}>
                        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
                            <Button size="large" onClick={() => { navigate("/report") }} variant="outlined" color="inherit" disableRipple sx={{ mr: 2, mb: 1, textTransform: "none", textDecoration: location.pathname === "/report" ? "underline" : "none", "&:hover": { textDecoration: "underline" } }}>Schaden melden</Button>
                            <Button size="large" onClick={() => { navigate("/complete") }} variant="outlined" color="inherit" disableRipple sx={{ mr: 2, mb: 1, textTransform: "none", textDecoration: location.pathname === "/complete" ? "underline" : "none", "&:hover": { textDecoration: "underline" } }}>Meldung anzeigen</Button>
                            <Button size="large" onClick={() => { navigate("/claims") }} variant="outlined" color="inherit" disableRipple sx={{ mb: 1, textTransform: "none", textDecoration: location.pathname === "/claims" ? "underline" : "none", "&:hover": { textDecoration: "underline" } }}>Meine Schäden</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Toolbar>
            <Collapse in={collapse} sx={{ zIndex: theme.zIndex.drawer + 1 }}>
                <Paper elevation={0} sx={{ backgroundImage: "none", borderRadius: 0 }}>
                    <MenuList dense>
                        <MenuItem onClick={() => { handlePageChange("/report"); }}>
                            <ListItemText inset>Melden</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => { handlePageChange("/complete"); }}>
                            <ListItemText inset>Meldung anzeigen</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => { handlePageChange("/claims"); }}>
                            <ListItemText inset>Meine Schäden</ListItemText>
                        </MenuItem>
                    </MenuList>
                    <Divider />
                </Paper>
            </Collapse>
            <Collapse in={userCollapse} sx={{ zIndex: theme.zIndex.drawer + 1 }}>
                <Paper elevation={0} sx={{ backgroundImage: "none", borderRadius: 0 }}>
                    <MenuList dense>
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon>
                                <ExitToAppIcon sx={{ color: theme.palette.text.primary }} />
                            </ListItemIcon>
                            <ListItemText>Abmelden</ListItemText>
                        </MenuItem>
                    </MenuList>
                    <Divider />
                </Paper>
            </Collapse>
        </AppBar>
    );
};

export default Nav;