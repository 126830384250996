import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Avatar, Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

const Photo = ({ data, photoPreview }) => {
    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));

    const [photos, setPhotos] = useState([]);
    const [preview, setPreview] = useState([]);

    useEffect(() => {
        if (data) {
            setPhotos(data);
            // console.log("Photos:", data);
        }
        if (photoPreview) {
            setPreview(photoPreview);
            // console.log("preview: ", photoPreview);
        }
        //eslint-disable-next-line
    }, [data]);

    const checkInput = (input, notApplicable) => {
        if (input && input?.length > 0) {
            return input;
        };
        if (notApplicable) {
            return "Nicht anwendbar";
        };
        return "Nicht gefunden";
    };

    const Preview = ({ photo, index }) => {

        return (
            <Grid item xs={12} sx={{ border: 1, borderColor: "text.secondary", borderRadius: "4px", width: "100%", mt: index === 0 && !belowsm ? 0 : 2 }}>
                <Typography variant="h6" noWrap fontWeight={600} color={theme.palette.text.primary} sx={{ py: .25, px: 1, bgcolor: "divider", borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }}>{photo?.filename}</Typography>
                <Box sx={{ p: 1 }}>
                    <Grid container sx={{ justifyContent: belowsm ? "center" : "flex-start" }}>
                        <Grid item xs={12} sm={3} sx={{ maxWidth: "100%", display: "flex", justifyContent: "center" }}>
                            <img alt={`preview-${index}`} src={preview[index]} style={{ borderRadius: "4px" }} />
                        </Grid>
                        <Grid item xs={12} sm={9} zeroMinWidth sx={{ pl: 2 }}>
                            <Grid container sx={{ mb: 1, mt: belowsm ? 2 : 0 }}>
                                <Grid item>
                                    <Avatar sx={{ height: 20, width: 20, bgcolor: photo?.related ? "success.main" : "error.main", mr: 2, mt: .2 }}>
                                        {photo?.related ? <CheckIcon sx={{ color: "#fff", fontSize: "16px" }} /> : <PriorityHighIcon sx={{ color: "#fff", fontSize: "12px" }} />}
                                    </Avatar>
                                </Grid>
                                <Grid item xs zeroMinWidth>
                                    <Typography color={theme.palette.text.primary}>{photo?.related ? "Foto passt zur Schadenbeschreibung" : "Foto passt nicht zur Schadenbeschreibung"}</Typography>
                                </Grid>
                            </Grid>
                            <Typography color={theme.palette.text.primary}>{photo?.description ?? ""}</Typography>
                            <Grid container columnGap={belowsm ? 0 : 2} sx={{ mt: 1 }}>
                                <Grid item>
                                    <Typography color={theme.palette.text.primary}>Begründung:</Typography>
                                    <Typography color={theme.palette.text.primary} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(photo?.documentTypeReason)}</Typography>
                                </Grid>
                                <Grid item xs zeroMinWidth sx={{ display: !belowsm ? "block" : "none" }}>
                                    <Typography color={theme.palette.text.primary}>{checkInput(photo?.documentTypeReason)}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ pl: 2, pr: 1, mt: 1, justifyContent: belowsm ? "flex-start" : "flex-end", display: photo.hasCar ? "flex" : "none" }}>
                            <Grid item xs={12} sm={5} zeroMinWidth sx={{ pr: belowmd ? 0 : 1 }}>
                                <Typography variant="h6" fontWeight={600} color={theme.palette.text.primary}>Fahrzeugdaten</Typography>
                                <Grid container columnGap={belowmd ? 0 : 2}>
                                    <Grid item xs={belowmd ? null : "auto"} zeroMinWidth>
                                        <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0, width: "166px" }}>Kennzeichen:</Typography>
                                        <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(photo?.carLicensePlate, true)}</Typography>
                                        <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0, width: "166px" }}>Fahrgestellnummer:</Typography>
                                        <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(photo?.carVin, true)}</Typography>
                                        <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0, width: "166px" }}>Baujahr:</Typography>
                                        <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(photo?.carYear, true)}</Typography>
                                    </Grid>
                                    <Grid item xs zeroMinWidth sx={{ display: !belowmd ? "block" : "none" }}>
                                        <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(photo?.carLicensePlate, true)}</Typography>
                                        <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(photo?.carVin, true)}</Typography>
                                        <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(photo?.carYear, true)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={4} zeroMinWidth sx={{ pr: belowmd ? 0 : 1, mt: belowsm ? 0 : "32px" }}>
                                <Grid container columnGap={belowmd ? 0 : 2}>
                                    <Grid item xs={belowmd ? null : "auto"} zeroMinWidth>
                                        <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0 }}>Marke:</Typography>
                                        <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(photo?.carMake, true)}</Typography>
                                        <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0 }}>Modell:</Typography>
                                        <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(photo?.carModel, true)}</Typography>
                                        <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0 }}>Farbe:</Typography>
                                        <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(photo?.carColor, true)}</Typography>
                                    </Grid>
                                    <Grid item xs zeroMinWidth sx={{ display: !belowmd ? "block" : "none" }}>
                                        <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(photo?.carMake, true)}</Typography>
                                        <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(photo?.carModel, true)}</Typography>
                                        <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(photo?.carColor, true)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={9} zeroMinWidth sx={{ mt: belowmd ? 0 : 1 }}>
                                <Grid container columnGap={belowmd ? 0 : 2}>
                                    <Grid item xs={belowmd ? null : "auto"} zeroMinWidth>
                                        <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0, width: "166px" }}>Fahrzeugschaden:</Typography>
                                        <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(photo?.carDamage, true)}</Typography>
                                        <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0, width: "166px" }}>Fahrzeugschadenort:</Typography>
                                        <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(photo?.carDamageLocation, true)}</Typography>
                                        <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0, width: "166px" }}>Fahrzeugort:</Typography>
                                        <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(photo?.carLocation, true)}</Typography>
                                        <Typography noWrap color={theme.palette.text.primary} sx={{ flexGrow: belowmd ? 1 : 0, width: "166px" }}>Kilometerstand:</Typography>
                                        <Typography color={theme.palette.text.primary} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(photo?.carMileage, true)}</Typography>
                                    </Grid>
                                    <Grid item xs zeroMinWidth sx={{ display: !belowmd ? "block" : "none" }}>
                                        <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(photo?.carDamage, true)}</Typography>
                                        <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(photo?.carDamageLocation, true)}</Typography>
                                        <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(photo?.carLocation, true)}</Typography>
                                        <Typography noWrap={belowmd ? false : true} color={theme.palette.text.primary}>{checkInput(photo?.carMileage, true)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box >
            </Grid >
        );
    };

    if (photos.length === 0) {
        return (
            <Box sx={{ height: belowsm ? "calc(100vh - 237px)" : "auto", width: belowsm ? "90vw" : "80vw", overflow: "auto", display: "flex", justifyContent: "center", alignItems: "center", pb: belowsm ? 2 : 0, mt: belowsm ? 0 : "35vh" }}>
                <Typography color={theme.palette.text.primary}>Fehler mit der Anzeige vom Schadenfoto.</Typography>
            </Box>
        )
    }

    return (
        <Grid container direction={belowsm ? "row" : "column"} sx={{ height: belowsm ? "calc(100vh - 237px)" : "auto", overflow: "auto", justifyContent: "center", alignItems: "center", pb: belowsm ? 2 : 0 }}>
            {photos?.map((p, i) => { return <Preview key={i} photo={p} index={i} /> })}
        </Grid>
    );
};

export default Photo;