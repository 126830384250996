import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Backdrop, Box, Button, CircularProgress, Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Navigate } from "react-router-dom";
import swiftRobin from '../images/swift_robin.png';


const Login = () => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));

    const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();

    const login = () => {
        loginWithRedirect();
    }

    if (isLoading) {
        return (
            <Backdrop
                sx={{ zIndex: theme.zIndex.drawer + 1 }}
                invisible
                open={true}
            >
                <CircularProgress
                    size={108}
                    thickness={2}
                    sx={{ color: "#fff" }}
                />
            </Backdrop>
        )
    }

    if (!isAuthenticated) {
        return (
            <Container sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh", width: belowsm ? "320px" : "335px" }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mb: 2,
                }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                            height: "64px",
                            width: "64px",
                            mr: 2,
                            p: 1
                        }}>
                        <img alt="logo" src={swiftRobin} style={{ height: "65%" }} />
                    </Avatar>
                    <Typography variant="h4" fontWeight={700} color={theme.palette.text.primary} sx={{ ml: 2 }}>Swift Robin</Typography>
                </Box>
                <Button
                    variant="contained"
                    disableElevation
                    onClick={login}
                    size="large"
                    fullWidth
                    sx={{
                        textTransform: "none",
                        bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                        "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" }
                    }}>
                    Anmelden
                </Button>
            </Container>
        );
    }

    return <Navigate replace to="/report" />;
};

export default Login;