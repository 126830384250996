import { useTheme } from "@emotion/react";
import { Box, Grid, TextField, Typography, useMediaQuery } from "@mui/material";

const Message = ({ data }) => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Grid container direction={belowsm ? "row" : "column"} sx={{ mt: belowsm ? 2 : 0, height: belowsm ? "calc(100vh - 257px)" : "auto", overflow: "auto" }}>
            <Grid item xs={12}>
                <Box sx={{ height: belowsm ? "auto" : "100%", border: 1, borderColor: "text.secondary", borderRadius: "4px" }}>
                    <Typography variant="h6" noWrap fontWeight={600} color={theme.palette.text.primary} sx={{ py: .25, px: 1, bgcolor: "divider", borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }}>Schadenmeldung</Typography>
                    <Box sx={{ p: 1 }}>
                        <Typography color={theme.palette.text.primary} sx={{ display: data?.hint ? "flex" : "none" }}>{`${data?.hint}`}</Typography>
                        <Typography color={theme.palette.text.primary} sx={{ display: data?.hint ? "none" : "flex" }}>{`${data?.lossDate ? `Am ${data?.lossDate} ` : ""}${data?.sender ? `${data?.sender}` : ""} ${!data?.lossDate && !data?.sender ? "Hat" : "hat"} folgende Nachricht übermittelt:`}</Typography>
                        <TextField
                            label="Meldungstext"
                            value={data?.message ?? "Kein Meldungstext vorhanden."}
                            multiline
                            rows={data?.message ? belowsm ? 14 : 18 : 1}
                            fullWidth
                            required
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                            sx={{
                                mt: 2,
                                "& label.Mui-focused": {
                                    color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
                                },
                                "& .MuiOutlinedInput-root": {
                                    "&:hover fieldset": {
                                        borderColor: theme.palette.mode === "dark" ? theme.palette.action.disabled : theme.palette.action.active,
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
                                    }
                                }
                            }}
                        />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default Message;