import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

const useAxios = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [isAxiosInitialized, setIsAxiosInitialized] = useState(false);
  const [isError, setIsError] = useState(false);

  const axiosInstance = useRef(null);
  const attempts = useRef(0);

  const initializeAxiosInstance = useCallback(async () => {
    attempts.current += 1;
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: window.env.AUTH0_AUDIENCE
        }
      });

      const instance = axios.create({
        baseURL: window.env.REACT_APP_apiUri,
      });

      instance.interceptors.request.use(
        config => {
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        },
        error => {
          console.error(`(Attempt ${attempts.current}) Error in request interceptor:`, error);
          return Promise.reject(error);
        }
      );

      axiosInstance.current = instance;
      setIsAxiosInitialized(true);
    } catch (error) {
      console.error(`(Attempt ${attempts.current}) Failed to get access token:`, error);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (!axiosInstance.current && attempts.current < 3) {
      initializeAxiosInstance();
    }
    if (attempts.current >= 3) {
      setIsError(true);
    }
  }, [initializeAxiosInstance])

  return { axiosInstance: axiosInstance.current, isAxiosInitialized, isError };
};

export default useAxios;