import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useNavigate } from "react-router-dom";

const ClaimDialog = forwardRef((props, ref) => {

    const theme = useTheme();

    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [claim, setClaim] = useState();

    useImperativeHandle(ref, () => ({
        open(data) {
            setClaim(data);
            setOpen(true);
        }
    }));

    const handleOpen = () => {
        localStorage.setItem("claim", claim?.claimUUID);
        navigate(`/complete?claim=${claim?.claimUUID}`)
    };

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Dialog
            fullWidth
            maxWidth={'lg'}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle fontWeight={600} sx={{ textAlign: "center" }}>Diesen Schaden laden?</DialogTitle>
            <DialogContent>
                <Typography variant="body1" color={theme.palette.text.primary} sx={{ mx: 2, lineHeight: "normal" }}>{`Gesendet am: ${claim?.sentAt ? moment(claim.sentAt).format("DD.MM.YYYY") : "Nicht vorhanden"}`}</Typography>
                <Typography variant="body1" color={theme.palette.text.primary} sx={{ mx: 2, mt: .5, lineHeight: "normal" }}>{`Melder: ${claim?.sender ? `${claim?.sender}` : "Nicht vorhanden"}`}</Typography>
                <Typography variant="body1" color={theme.palette.text.primary} sx={{ mx: 2, mt: .5, lineHeight: "normal" }}>{`Polizze: ${claim?.policy ? `${claim?.policy}` : "Nicht vorhanden"}`}</Typography>
                <Typography variant="body1" color={theme.palette.text.primary} sx={{ mx: 2, mt: .5, lineHeight: "normal" }}>{`Versicherung: ${claim?.insurance ? `${claim?.insurance}` : "Nicht vorhanden"}`}</Typography>
                <Typography variant="body1" color={theme.palette.text.primary} sx={{ mx: 2, mt: .5, lineHeight: "normal" }}>{`Risiko: ${claim?.risk ? `${claim?.risk}` : "Nicht vorhanden"}`}</Typography>
                <Typography variant="body1" color={theme.palette.text.primary} sx={{ mx: 2, mt: .5, lineHeight: "normal" }}>{`Ursache: ${claim?.cause ? `${claim?.cause}` : "Nicht vorhanden"}`}</Typography>
                <Typography variant="body1" color={theme.palette.text.primary} sx={{ mx: 2, mt: .5, lineHeight: "normal" }}>{`Schadenbeschreibung: ${claim?.lossDescription ? `${claim?.lossDescription}` : "Nicht vorhanden"}`}</Typography>
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item xs={6} sx={{ pr: .5 }}>
                        <Button variant="contained" fullWidth disableElevation onClick={handleOpen} sx={{ textTransform: "none", bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main", "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" } }}>Ja</Button>
                    </Grid>
                    <Grid item xs={6} sx={{ pl: .5 }}>
                        <Button variant="contained" fullWidth disableElevation onClick={handleClose} sx={{ textTransform: "none", bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main", "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" } }}>Nein</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );

});

export default ClaimDialog;