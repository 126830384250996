import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { Backdrop, Box, Button, CircularProgress, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Fragment, forwardRef, useImperativeHandle, useState } from "react";
import useAxios from '../../utlis/useAxios';
import Attachment from "./Attachment";
import FnolAttachment from './FnolAttachment';
import Message from "./Message";
import Overview from "./Overview";
import Photo from "./Photo";
import PoliceReportAttachment from './PoliceReportAttachment';

const ViewHandler = forwardRef((props, ref) => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));

    const [claim, setClaim] = useState();
    const [photo, setPhoto] = useState();
    const [attachment, setAttachment] = useState();
    const [photoPreview, setPhotoPreview] = useState([]);
    const [attachmentPreview, setAttachmentPreview] = useState([]);
    const [source, setSource] = useState([]);
    const [displayOverview, setDisplayOverview] = useState(false);
    const [displayMessage, setDisplayMessage] = useState(false);
    const [displayPhoto, setDisplayPhoto] = useState(false);
    const [displayAttachment, setDisplayAttachment] = useState(false);
    const [displayFnol, setDisplayFnol] = useState(false);
    const [displayPoliceReport, setDisplayPoliceReport] = useState(false);
    const [displaySource, setDisplaySource] = useState(false);
    const [loading, setLoading] = useState(false);

    const { axiosInstance, isAxiosInitialized } = useAxios();

    //eslint-disable-next-line
    const fetchPdf = async () => {
        if (!isAxiosInitialized) return;
        setLoading(true);

        await axiosInstance({
            url: `/pdf/${claim?.claimUUID}`,
            method: "GET",
            responseType: "blob"
        }).then((res) => {
            const blob = new Blob([res.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `pdf-report-${claim?.claimUUID}.pdf`);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        }).catch(err => console.error(err));
        setLoading(false);
    };

    const fetchSource = async (data) => {
        if (!isAxiosInitialized) return;
        setLoading(true);

        await axiosInstance({
            url: data?.path || "",
            method: "GET",
            responseType: "blob"
        }).then((res) => {
            const blob = new Blob([res.data], { type: data?.contentType || "text/plain" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${data?.filename || "source-document.txt"}`);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        }).catch(err => console.error(err));
        setLoading(false);
    };

    const downloadAllSource = async () => {
        for (let i = 0; i < source?.length; i++) {
            await fetchSource(source[i]);
        };
    };

    useImperativeHandle(ref, () => ({
        openOverview(data) {
            setClaim(data);
            setDisplayOverview(true);
            setDisplayMessage(false);
            setDisplayPhoto(false);
            setDisplayFnol(false);
            setDisplayPoliceReport(false);
            setDisplayAttachment(false);
            setDisplaySource(false);
        },
        handleMessage(data) {
            setClaim(data);
            setDisplayMessage(true);
            setDisplayOverview(false);
            setDisplayPhoto(false);
            setDisplayFnol(false);
            setDisplayPoliceReport(false);
            setDisplayAttachment(false);
            setDisplaySource(false);
        },
        handlePhoto(data, preview, sourceArray) {
            setPhoto(data);
            setPhotoPreview(preview);
            setSource(sourceArray);
            setDisplayOverview(false);
            setDisplayMessage(false);
            setDisplayFnol(false);
            setDisplayPoliceReport(false);
            setDisplayAttachment(false);
            setDisplayPhoto(true);
            setDisplaySource(true);
        },
        handleAttachment(data, preview, sourceArray) {
            setAttachment(data);
            setAttachmentPreview(preview);
            setSource(sourceArray);
            setDisplayOverview(false);
            setDisplayMessage(false);
            setDisplayPhoto(false);
            setDisplayFnol(false);
            setDisplayPoliceReport(false);
            setDisplayAttachment(true);
            setDisplaySource(true);
        },
        handleFnol(data, preview, sourceArray) {
            setAttachment(data);
            setAttachmentPreview(preview);
            setSource(sourceArray)
            setDisplayOverview(false);
            setDisplayMessage(false);
            setDisplayPhoto(false);
            setDisplayAttachment(false);
            setDisplayPoliceReport(false);
            setDisplayFnol(true);
            setDisplaySource(true);
        },
        handlePoliceReport(data, preview, sourceArray) {
            setAttachment(data);
            setAttachmentPreview(preview);
            setSource(sourceArray);
            setDisplayOverview(false);
            setDisplayMessage(false);
            setDisplayPhoto(false);
            setDisplayAttachment(false);
            setDisplayFnol(false);
            setDisplayPoliceReport(true);
            setDisplaySource(true);
        }
    }));

    const closeView = () => {
        setDisplayOverview(false);
        setDisplayMessage(false);
        setDisplayPhoto(false);
        setDisplayAttachment(false);
        setDisplayFnol(false);
        setDisplayPoliceReport(false);
        setDisplaySource(false);
        if (belowsm) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const WithActionButtons = ({ component }) => {
        return (
            <Fragment>
                <Box sx={{ height: belowsm ? "calc(100vh - 228px)" : belowmd ? "calc(100vh - 226px)" : "calc(100vh - 185px)", overflow: "auto" }}>
                    {component}
                </Box>
                <Grid container sx={{ borderTop: belowsm ? 1 : 0, borderTopColor: "text.secondary" }}>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button
                        onClick={fetchPdf}
                        disableElevation
                        variant="contained"
                        size="large"
                        fullWidth={belowsm ? true : false}
                        startIcon={<PictureAsPdfOutlinedIcon />}
                        disabled={claim?.claim === false || (claim?.personalInjury && claim?.chatClaimRouting?.car === false)}
                        sx={{
                            mt: 2,
                            mb: belowsm ? 0 : 2,
                            mr: belowsm ? 0 : 1,
                            textTransform: "none",
                            bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                            "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" }
                        }}>PDF Report
                    </Button>
                    <Button
                        onClick={downloadAllSource}
                        disableElevation
                        variant="contained"
                        size="large"
                        fullWidth={belowsm ? true : false}
                        startIcon={<DownloadForOfflineOutlinedIcon />}
                        sx={{
                            display: displaySource ? "inline-flex" : "none",
                            mt: belowsm ? 1 : 2,
                            mb: belowsm ? 0 : 2,
                            mr: belowsm ? 0 : 1,
                            textTransform: "none",
                            bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                            "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" }
                        }}>Original Download
                    </Button>
                    <Button
                        onClick={closeView}
                        disableElevation
                        variant="contained"
                        size="large"
                        fullWidth={belowsm ? true : false}
                        startIcon={<ArrowCircleLeftOutlinedIcon />}
                        sx={{
                            mt: belowsm ? 1 : 2,
                            mb: 2,
                            textTransform: "none",
                            bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                            "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" }
                        }}>Zurück
                    </Button>
                </Grid>
            </Fragment>
        );
    }

    if (loading) {
        return (
            <Grid container sx={{ p: 2, height: belowsm ? "calc(100vh - 247px)" : belowmd ? "calc(100vh - 233px)" : "calc(100vh - 184px)" }}>
                <Backdrop
                    sx={{ color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Grid>
        );
    }

    if (displayOverview) {
        return <WithActionButtons component={<Overview claimProps={claim} />} />
    }

    if (displayPhoto) {
        return <WithActionButtons component={<Photo data={photo} photoPreview={photoPreview} />} />
    }

    if (displayAttachment) {
        return <WithActionButtons component={<Attachment data={attachment} attachmentPreview={attachmentPreview} />} />
    }
    if (displayMessage) {
        return <WithActionButtons component={<Message data={claim} />} />
    }

    if (displayFnol) {
        return <WithActionButtons component={<FnolAttachment data={attachment} attachmentPreview={attachmentPreview} />} />
    }
    if (displayPoliceReport) {
        return <WithActionButtons component={<PoliceReportAttachment data={attachment} attachmentPreview={attachmentPreview} />} />
    }

    return (
        <Box sx={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography noWrap color={theme.palette.text.primary}>Keine Ansicht ausgewählt.</Typography>
        </Box>
    );

});

export default ViewHandler;