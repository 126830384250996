import { Auth0Provider, withAuthenticationRequired } from '@auth0/auth0-react';
import { ThemeProvider, createTheme, useMediaQuery } from '@mui/material';
import { deDE } from '@mui/material/locale';
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Claims from './components/Claims';
import Complete from './components/Complete/Complete';
import Login from './components/Login';
import Nav from './components/Nav';
import Report from './components/Report';

const ProtectedRoute = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

const domain = window.env.AUTH0_URL;
const clientId = window.env.AUTH0_CLIENT_ID;
const audience = window.env.AUTH0_AUDIENCE;
function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = createTheme({
    palette: {
      mode: prefersDarkMode ? "dark" : "light",
      primary: {
        main: "#003781"
      }
    },
    typography: {
      fontFamily: 'Inter'
    }
  },
    deDE
  );

  const Redirect = () => {
    return <Navigate replace to="/login" />;
  };

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Auth0ProviderWithRedirectCallback
          domain={domain}
          clientId={clientId}
          authorizationParams={{
            redirect_uri: `${window.location.origin}/login`,
            audience: audience,
            ui_locales: "de",
          }}
        >
          <Nav />
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/report" element={<ProtectedRoute component={Report} />} />
            <Route exact path="/complete" element={<ProtectedRoute component={Complete} />} />
            <Route exact path="/claims" element={<ProtectedRoute component={Claims} />} />
            <Route exact path="*" element={<Redirect />} />
          </Routes>
        </Auth0ProviderWithRedirectCallback>
      </ThemeProvider >
    </BrowserRouter>
  );
}

export default App;